@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.container {
    .headSection {
        padding: 0;

        .layer {
            width: 45%;
            height: 100%;
            padding-top: $header-height;
            background: rgba(5, 28, 45, 0.7);

            @include respond-above(xl) {
                width: 48%;
            }

            @include respond-below(lg) {
                width: 100%;
                padding-top: $header-resp-height;
            }

            .contentContainer {
                padding: 12px 72px 82px calc((100vw + 15px - #{$content-width}) / 2);

                @include title($secondary-color, 31px);

                @include content() {
                    margin-bottom: 35px;
                }

                @include respond-below(lg) {
                    padding: 12px 12px 82px 12px;
                }

                & > div {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .howItWorks {
                    margin: 20px 0;
                }
            }
        }
    }

    .chartSection {
        text-align: center;

        @include title($text-dark) {
            max-width: 600px;
            margin: 0 auto 40px auto;

            @include respond-below(lg) {
                font-size: 20px;
            }
        }

        @include content($primary-color) {
            margin-top: 20px;
            font-size: 12px;
        }

        .chart {
            max-width: 100%;

            @include respond-below(lg) {
                display: none;
            }
        }

        .respChart {
            display: none;
            max-width: 100%;

            @include respond-below(lg) {
                display: block;
            }
        }
    }

    .cardSection {
        overflow: hidden;

        .cardGroup {
            display: flex;
            margin: 0 -20px;

            @include respond-below(lg) {
                flex-direction: column;
            }

            @include title($text-dark) {
                height: 72px;
                font-size: 20px;
            }

            @include content($text-dark);
        }
    }

    .textSection {
        text-align: center;

        @include title($primary-color);
        @include content();
    }

    .gridSection {
        text-align: center;

        .iconGroup {
            display: flex;

            @include respond-below(lg) {
                flex-direction: column;
            }
        }

        @include title($text-dark) {
            margin-bottom: 10px;
        }
    }

    .gridTwoSection {
        text-align: center;

        .iconGroup {
            display: flex;

            @include respond-below(lg) {
                flex-wrap: wrap;

                & > * {
                    flex: 0 0 50%;
                }
            }
        }

        @include title($text-dark) {
            margin-bottom: 10px;
        }
    }

    .bannerSection {
        padding: 0;

        .layer {
            float: right;
            width: 45%;
            height: 100%;
            background: rgba(197, 189, 160, 0.7);

            @include respond-below(lg) {
                width: 100%;
            }

            .contentContainer {
                padding: 90px calc((100vw - #{$content-width}) / 2) 100px 60px;

                @include title($text-dark, 31px);

                @include content($text-dark) {
                    margin-bottom: 35px;
                }

                @include respond-below(lg) {
                    padding: 60px 12px;
                }
            }
        }
    }

    .contactSection {
        .info {
            float: left;
            width: 30%;

            @include respond-below(lg) {
                width: 100%;
            }

            @include title($primary-color, 31px) {
                text-align: left;
            }

            @include content($secondary-color) {
                padding-right: 15px;
                text-align: left;

                a {
                    color: $secondary-color;
                }
            }
        }

        .form {
            float: left;
            width: 70%;
            padding-top: 60px;

            @include respond-below(lg) {
                width: 100%;
                padding-top: 20px;
            }
        }
    }
}
