@mixin home-title($font-size: 38px) {
    color: $secondary-color;
    font-weight: bold;
    font-size: $font-size;
    font-family: $secondary-font-family;
    line-height: 1.2;
    letter-spacing: 0.2px;

    @include respond-below(sm) {
        font-size: $font-size * 0.84;
    }

    @content;
}

@mixin home-subtitle($font-size: 20px) {
    color: $secondary-color;
    font-size: $font-size;
    font-family: $primary-font-family;
    line-height: 1.2;

    @include respond-below(sm) {
        font-size: $font-size * 0.8;
    }

    @content;
}
